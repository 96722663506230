<template>
  <div>
    <v-row class="mx-12 mt-n4">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card width="1000" flat class="mt-8">
          <v-row no-gutters>
            <v-col cols="3" class="text-right">
              <v-card color="transparent" flat>
                <v-card-text class="headline primary--text">
                  Account
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3"
              ><v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogPersonalJoint = !dialogPersonalJoint"
                    color="success"
                    class="title ml-n1 mt-5"
                    >mdi-pencil</v-icon
                  ></template
                >
                <span>Edit</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-card color="transparent" flat>
                <v-card-text class="headline primary--text">
                  Basic
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogBasicJoint = !dialogBasicJoint"
                    color="success"
                    class="title ml-n1 mt-5"
                    >mdi-pencil</v-icon
                  ></template
                >
                <span>Edit</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text  mt-n6">
                Name:
              </v-card-text>
            </v-col>
            <v-col cols="3"
              ><v-card-text class="subtitle-1 mt-n6">
                {{ profileJoint.title }} {{ profileJoint.firstName }}
                {{ profileJoint.familyName }}
              </v-card-text></v-col
            >
            <v-col cols="3" class="text-right"
              ><v-card-text class="subtitle-1 grey--text  mt-n6">
                Gender:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n6">
                {{ profileJoint.gender }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text  mt-n8">
                Account Name:
              </v-card-text>
            </v-col>
            <v-col cols="3"
              ><v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.name }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text mt-n8">
                Martial status:
              </v-card-text>
            </v-col>
            <v-col cols="3"
              ><v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.martialStatus }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text  mt-n8">
                Account Type:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.type }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text mt-n8">
                Residency status:
              </v-card-text>
            </v-col>
            <v-col cols="3"
              ><v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.residencyStatus }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right"> </v-col>

            <v-col cols="1"> </v-col>
            <v-col cols="2" />
            <v-col cols="3" class="text-right"
              ><v-card-text class="subtitle-1 grey--text mt-n8">
                Date of birth:
              </v-card-text>
            </v-col>
            <v-col cols="3"
              ><v-card-text class="subtitle-1  mt-n8">
                {{ profileJoint.birthDate }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right"
              ><v-card color="transparent" flat>
                <v-card-text class="headline primary--text mt-3">
                  Address
                </v-card-text>
              </v-card></v-col
            >
            <v-col cols="3"
              ><v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogAddressJoint = !dialogAddressJoint"
                    color="success"
                    class="title ml-n1 mt-8"
                    >mdi-pencil</v-icon
                  ></template
                >
                <span>Edit</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3" class="text-right"
              ><v-card color="transparent" flat>
                <v-card-text class="headline primary--text mt-3">
                  Contact
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3"
              ><v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogContactJoint = !dialogContactJoint"
                    color="success"
                    class="title ml-n1 mt-8"
                    >mdi-pencil</v-icon
                  ></template
                >
                <span>Edit</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right mt-n6">
              <v-card-text class="subtitle-1 grey--text">
                Residential:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n6">
                {{ profileJoint.resNumber }}
                {{ profileJoint.resStreet }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text mt-n6">
                Pref. contact:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n6">
                {{ profileJoint.prefContactMethod }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right"> </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.resSuburb }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text mt-n8">
                Email address:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.email }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right"> </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.resCity }}
                {{ profileJoint.resPostcode }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text mt-n8">
                Mobile phone:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.mobilePhone }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right"> </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.resCountry }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text mt-n8">
                Work phone:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.workPhone }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text mt-n4">
                Mailing:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n4">
                {{ profileJoint.mailNumber }}
                {{ profileJoint.mailStreet }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text mt-n8">
                Home phone:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.homePhone }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right"> </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.mailSuburb }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 grey--text mt-n12">
                Pref. phone:
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n12">
                {{ profileJoint.prefContactNumber }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right"> </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.mailCity }}
                {{ profileJoint.mailPostcode }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right"> </v-col>
            <v-col cols="3"> </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3" class="text-right"> </v-col>
            <v-col cols="3">
              <v-card-text class="subtitle-1 mt-n8">
                {{ profileJoint.mailCountry }}
              </v-card-text>
            </v-col>
            <v-col cols="3" class="text-right"> </v-col>
            <v-col cols="3"> </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="dialogPersonalJoint" width="400px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update personal details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="profileJoint.title"
                    :items="titleItems"
                    label="Title"
                  ></v-select>
                </v-col>
                <v-col cols="8"> </v-col>
                <v-col cols="6" class="mt-n5">
                  <v-text-field
                    v-model="profileJoint.firstName"
                    :rules="nameReqRules"
                    label="First name"
                /></v-col>
                <v-col cols="6" class="mt-n5">
                  <v-text-field
                    v-model="profileJoint.familyName"
                    :rules="nameReqRules"
                    label="Family name"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="primary--text" @click="close">Cancel</v-btn>
              <v-btn
                text
                color="success"
                :disabled="!valid"
                @click="updateJoint"
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogBasicJoint" width="400px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update basic details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="profileJoint.gender"
                    :items="genderItems"
                    label="Gender"
                  ></v-select>
                  <v-select
                    v-model="profileJoint.martialStatus"
                    :items="martialStatusItems"
                    label="Martial status"
                  ></v-select>
                  <v-select
                    v-model="profileJoint.residencyStatus"
                    :items="residencyStatusItems"
                    label="Residency status"
                  ></v-select>
                  <template>
                    <v-menu
                      ref="menuJoint"
                      v-model="menuJoint"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      top
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="profileJoint.birthDate"
                          readonly
                          label="Date of birth"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="profileJoint.birthDate"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="saveJoint"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="primary--text" @click="close">Cancel</v-btn>
              <v-btn
                text
                color="success"
                :disabled="!valid"
                @click="updateJoint"
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogAddressJoint" width="500px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update address details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-card-text class="title ml-n4">
                    Residential
                  </v-card-text></v-col
                >
                <v-col cols="6">
                  <v-card-text class="title ml-n4">
                    Mailing
                  </v-card-text>
                  <v-btn
                    @click="copyResidentialJoint"
                    color="success"
                    text
                    small
                    class="mt-n5"
                  >
                    (copy residental)</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="mt-n4">
                  <v-text-field
                    v-model="profileJoint.resNumber"
                    :rules="numberRules"
                    label="Number"
                  />
                  <v-text-field
                    v-model="profileJoint.resStreet"
                    :rules="nameRules"
                    label="Street"
                  />
                  <v-text-field
                    v-model="profileJoint.resSuburb"
                    :rules="nameRules"
                    label="Suburb"
                  />
                  <v-text-field
                    v-model="profileJoint.resCity"
                    :rules="nameRules"
                    label="Town / City"
                  />
                  <v-text-field
                    v-model="profileJoint.resPostcode"
                    :rules="numberRules"
                    label="Postcode"
                  />
                  <v-text-field
                    v-model="profileJoint.resCountry"
                    :rules="nameRules"
                    label="Country"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <v-text-field
                    v-model="profileJoint.mailNumber"
                    :rules="numberRules"
                    label="Number"
                  />
                  <v-text-field
                    v-model="profileJoint.mailStreet"
                    :rules="nameRules"
                    label="Street"
                  />
                  <v-text-field
                    v-model="profileJoint.mailSuburb"
                    :rules="nameRules"
                    label="Suburb"
                  />
                  <v-text-field
                    v-model="profileJoint.mailCity"
                    :rules="nameRules"
                    label="Town / City"
                  />
                  <v-text-field
                    v-model="profileJoint.mailPostcode"
                    :rules="numberRules"
                    label="Postcode"
                  />
                  <v-text-field
                    v-model="profileJoint.mailCountry"
                    :rules="nameRules"
                    label="Country"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="primary--text" @click="close">Cancel</v-btn>
              <v-btn
                text
                color="success"
                :disabled="!valid"
                @click="updateJoint"
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogContactJoint" width="400px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update contact details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="profileJoint.prefContactMethod"
                    :items="prefContactMethodItems"
                    label="Preferred method of contact"
                    prepend-icon="mdi-account-tie-voice"
                  ></v-select>
                  <v-text-field
                    v-model="profileJoint.email"
                    label="Email"
                    :rules="emailReqRules"
                    prepend-icon="mdi-email"
                  />
                  <v-text-field
                    v-model="profileJoint.mobilePhone"
                    label="Mobile phone"
                    :rules="phoneReqRules"
                    prepend-icon="mdi-phone"
                  />
                  <v-text-field
                    v-model="profileJoint.workPhone"
                    label="Work phone"
                    :rules="phoneRules"
                    prepend-icon="mdi-phone"
                  />
                  <v-text-field
                    v-model="profileJoint.homePhone"
                    label="Home phone"
                    :rules="phoneRules"
                    prepend-icon="mdi-phone"
                  />
                  <v-select
                    v-model="profileJoint.prefContactNumber"
                    :items="prefContactNumberItems"
                    label="Preferred contact number"
                    prepend-icon="mdi-card-account-phone"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="primary--text" @click="close">Cancel</v-btn>
              <v-btn
                text
                color="success"
                :disabled="!valid"
                @click="updateJoint"
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'clientProfilePersonTwo',

  data() {
    return {
      menuJoint: false,
      dialogPersonalJoint: false,
      dialogBasicJoint: false,
      dialogAddressJoint: false,
      dialogContactJoint: false,
      valid: true,
      titleItems: ['Mr', 'Mrs', 'Ms', 'Miss', 'Dr'],
      genderItems: StaticDataService.getCategoryGender(),
      prefContactMethodItems: ['Mail', 'Phone', 'Email', 'Falcon9'],
      prefContactNumberItems: ['Mobile phone', 'Work phone', 'Home phone'],
      martialStatusItems: ['Single', 'Married'],
      residencyStatusItems: ['Resident Visa', 'NZ Resident'],
      nameRules: [
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      numberRules: [
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberRulesCode: [
        v => !!v || 'Code is required',
        v =>
          (/^(?=.*\d).+$/.test(v) && v.length == 6) ||
          'Code must be 6 characters'
      ],
      phoneRules: [
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      phoneReqRules: [
        v => !!v || 'Phone is required',
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      emailReqRules: [
        v => !!v,
        v => !v || (!!v && /.+@.+\..+/.test(v)) || 'E-mail must be valid'
      ]
    }
  },

  watch: {
    menuJoint(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },

  methods: {
    saveJoint(date) {
      this.$refs.menuJoint.save(date)
    },
    copyResidentialJoint() {
      this.profileJoint.mailNumber = this.profileJoint.resNumber
      this.profileJoint.mailStreet = this.profileJoint.resStreet
      this.profileJoint.mailSuburb = this.profileJoint.resSuburb
      this.profileJoint.mailCity = this.profileJoint.resCity
      this.profileJoint.mailPostcode = this.profileJoint.resPostcode
      this.profileJoint.mailCountry = this.profileJoint.resCountry
    },
    updateJoint() {
      this.$store.dispatch('updateClientJoint', this.clientIdJoint).then(() => {
        this.dialogPersonalJoint = false
        this.dialogBasicJoint = false
        this.dialogAddressJoint = false
        this.dialogContactJoint = false
      })
    },
    close() {
      this.$store.dispatch('fetchClient', this.clientId).then(() => {
        this.dialogPersonalJoint = false
        this.dialogBasicJoint = false
        this.dialogAddressJoint = false
        this.dialogContactJoint = false
      })
    }
  },

  computed: {
    profileJoint: {
      get() {
        return this.client.profileJoint
      },
      set(attributes) {
        this.$store.commit('SET_CLIENT_PROFILE_JOINT', attributes)
      }
    },

    ...mapState(['client', 'system']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
