<template>
  <div>
    <v-dialog persistent v-model="visible" max-width="500px">
      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ currentTitle }}</span>

          <v-avatar
            color="primary lighten-2"
            class="subheading white--text"
            size="24"
            v-text="step"
          ></v-avatar>
        </v-card-title>
        <v-card flat height="430" class="mx-2">
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text class="subtitle-1 mx-2">
                <p>I plan to begin withdrawing money from my investments in</p>
                <v-radio-group
                  v-model="conditions.riskToleranceQuestion1"
                  :mandatory="true"
                  class="mx-4"
                >
                  <v-radio label="Less than 3 years" value="1"></v-radio>
                  <v-radio label="3-5 years" value="3"></v-radio>
                  <v-radio label="6-10 years" value="7"></v-radio>
                  <v-radio label="11 or more years" value="10"></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="2">
              <v-card-text class="subtitle-1 mx-2">
                <p class="mr-6">
                  Once I begin withdrawing from my investment, I plan to spend
                  all of the funds in
                </p>
                <v-radio-group
                  v-model="conditions.riskToleranceQuestion2"
                  :mandatory="true"
                  class="mx-4"
                >
                  <v-radio label="Less than 3 years" value="0"></v-radio>
                  <v-radio label="3-5 years" value="1"></v-radio>
                  <v-radio label="6-10 years" value="4"></v-radio>
                  <v-radio label="11 or more years" value="8"></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="3">
              <v-card-text class="subtitle-1 mx-2">
                <p>I would describe my knowledge of investments as</p>
                <v-radio-group
                  v-model="conditions.riskToleranceQuestion3"
                  :mandatory="true"
                  class="mx-4"
                >
                  <v-radio label="None" value="0"></v-radio>
                  <v-radio label="Limited" value="2"></v-radio>
                  <v-radio label="Good" value="4"></v-radio>
                  <v-radio label="Extensive" value="6"></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="4">
              <v-card-text class="subtitle-1 mx-2">
                <p>
                  When I invest money I am mostly concerned about my investment
                </p>
                <v-radio-group
                  v-model="conditions.riskToleranceQuestion4"
                  :mandatory="true"
                  class="mx-4"
                >
                  <v-radio label="Losing Value" value="0"></v-radio>
                  <v-radio label="Gaining Value" value="8"></v-radio>
                  <v-radio
                    label="Equally Concerned about Losing or Gaining Value"
                    value="4"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="5">
              <v-card-text class="subtitle-1 mx-2">
                <p class="mr-6">
                  What is the most aggressive investment you own or have owned
                  in the past?
                </p>
                <v-radio-group
                  v-model="conditions.riskToleranceQuestion5"
                  :mandatory="true"
                  class="mx-4"
                >
                  <v-radio label="Cash Equivalents" value="0"></v-radio>
                  <v-radio label="Fixed Interest" value="3"></v-radio>
                  <v-radio label="Property" value="6"></v-radio>
                  <v-radio label="Equities" value="8"></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="6">
              <v-card-text class="subtitle-1 mx-2">
                <p class="mr-12">
                  Consider the following scenrio:
                </p>
                <p class="mr-6">
                  Imagine that in the past three months, the overall stockmarket
                  loses 25% of its value. An individual stock investment you own
                  also lost 25% of its value.
                </p>
                <p class="mr-12">
                  What would you most likely do?
                </p>
                <v-radio-group
                  v-model="conditions.riskToleranceQuestion6"
                  :mandatory="true"
                  class="mx-4"
                >
                  <v-radio label="Sell ALL of my shares" value="0"></v-radio>
                  <v-radio label="Sell SOME shares" value="2"></v-radio>
                  <v-radio label="Do Nothing" value="6"></v-radio>
                  <v-radio label="Buy more Shares" value="8"></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="7">
              <v-card-text class="subtitle-1 mx-2 mt-n3">
                <p>
                  Which range of outcomes is most acceptable for you?
                </p>
                <p>Ten Year Projected Returns</p>
                <v-radio-group
                  v-model="conditions.riskToleranceQuestion7"
                  :mandatory="true"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Plan</th>
                          <th class="text-left">Average</th>
                          <th class="text-left">Low</th>
                          <th class="text-left">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><v-radio label="A" value="1"></v-radio></td>
                          <td>4%</td>
                          <td>3%</td>
                          <td>8%</td>
                        </tr>
                        <tr>
                          <td><v-radio label="B" value="2"></v-radio></td>
                          <td>5%</td>
                          <td>2%</td>
                          <td>10%</td>
                        </tr>
                        <tr>
                          <td><v-radio label="C" value="4"></v-radio></td>
                          <td>6%</td>
                          <td>1%</td>
                          <td>12%</td>
                        </tr>
                        <tr>
                          <td><v-radio label="D" value="6"></v-radio></td>
                          <td>7%</td>
                          <td>0%</td>
                          <td>15%</td>
                        </tr>
                        <tr>
                          <td><v-radio label="E" value="8"></v-radio></td>
                          <td>8%</td>
                          <td>-1%</td>
                          <td>17%</td>
                        </tr>
                        <tr>
                          <td><v-radio label="F" value="10"></v-radio></td>
                          <td>9%</td>
                          <td>-2%</td>
                          <td>20%</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-radio-group>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="8">
              <v-card-text
                v-if="
                  conditions.riskType &&
                    questionaireRiskType != conditions.riskType
                "
                class="subtitle-1 mx-2"
              >
                <p class="mr-8">
                  {{ questionaireDescription }}
                </p>
                <v-card color="warning" class="mt-12 mr-4">
                  <v-card-title
                    >Risktype is currently set to
                    {{ conditions.riskType }}</v-card-title
                  >
                  <v-card-actions>
                    <v-btn color="success" text @click="update"
                      >Keep {{ conditions.riskType }}</v-btn
                    ><v-spacer></v-spacer>
                    <v-btn color="success" text @click="setRiskTypeAndUpdate"
                      >Change to {{ questionaireRiskType }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-card-text>

              <v-card-text
                v-else-if="questionaireRiskType == conditions.riskType"
                class="subtitle-1 mx-2"
                ><p class="mr-8">
                  {{ questionaireDescription }}
                </p>
                <v-card flat color="warning" class="mt-12 ml-4 mr-8 pt-4">
                  <p class="title text-center ml-2 mr-8">
                    Your answers have confirmed the
                  </p>
                  <p class="title text-center ml-2 mr-8 mt-n4">
                    "{{ questionaireRiskType }}"
                  </p>
                  <p class="title text-center ml-2 mr-8 mt-n4 pb-4">
                    Risktype is the right choice for you
                  </p>
                </v-card>
              </v-card-text>

              <v-card-text v-else class="subtitle-1 mx-2">
                <p class="mr-8">
                  {{ questionaireDescription }}
                </p>
                <v-card flat color="warning" class="mt-12 ml-4 mr-8 pt-4">
                  <p class="title text-center ml-2 mr-8">
                    According to your answers, the
                  </p>
                  <p class="title text-center ml-2 mr-8 mt-n4">
                    "{{ questionaireRiskType }}"
                  </p>
                  <p class="title text-center ml-2 mr-8 mt-n4 pb-4">
                    Risktype is the right choice for you
                  </p>
                </v-card>
              </v-card-text>
            </v-window-item>
          </v-window>
        </v-card>

        <v-card-actions>
          <v-btn
            v-if="step != 1"
            text
            @click="step--"
            class="primary--text ml-4 mb-4"
          >
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn text class="mr-6 mb-4" @click="close">
            Close
          </v-btn>
          <v-btn
            v-if="step <= 7"
            text
            color="success"
            class="mr-6 mb-4"
            @click="step++"
          >
            Next
          </v-btn>
          <v-btn
            v-else-if="step == 8 && conditions.riskType == ''"
            text
            color="success"
            class="mr-6 mb-4"
            @click="setRiskTypeAndUpdate"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'dialogRiskTypeQuestionaire',

  props: {
    visible: { type: Boolean, default: false },
    selectedData: { type: Object }
  },

  data() {
    return {
      step: 1,
      length: 8,
      // prettier-ignore
      riskToleranceMatrix: [
          [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
          [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
          [1,1,1,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4],
          [1,1,1,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4],
          [1,1,1,2,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,5,5,5,5,5],
          [1,1,1,2,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,5,5,5,5,5],
          [1,1,1,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,5,6,6,6],
          [1,1,1,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,5,6,6,6],
          [1,1,1,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,5,6,6,6],
          [2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,6,6,6,6,6,6],
          [2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,6,6,6,6,6,6],
          [2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,6,6,6,6,6,6],
          [2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,6,6,6,6,6,6],
          [2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,4,4,4,4,4,4,5,5,5,5,5,5,5,6,6,6,6,6,6,6,6,6],
          [2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,4,4,4,4,4,4,5,5,5,5,5,5,5,6,6,6,6,6,6,6,6,6],
          [2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,4,4,4,4,4,4,5,5,5,5,5,5,5,6,6,6,6,6,6,6,6,6],
          [2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,4,4,4,4,4,4,5,5,5,5,5,5,5,6,6,6,6,6,6,6,6,6],
          [2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,4,4,4,4,4,4,5,5,5,5,5,5,5,6,6,6,6,6,6,6,6,6],
      ]
    }
  },

  methods: {
    update() {
      this.conditions.modelPortfolio = this.selectedData.modelPortfolio
      this.$store.dispatch('updateConditions').then(() => {
        this.close()
      })
    },

    setRiskTypeAndUpdate() {
      this.conditions.riskType = this.questionaireRiskType
      this.update()
    },

    cancel() {
      this.$store.dispatch('fetchClientLedger').then(() => {
        this.$emit('close')
      })
    },

    close() {
      this.$emit('close')
      this.step = 1
    }
  },

  computed: {
    conditions: {
      get() {
        return this.clientLedger.ledger.conditions
      },
      set(attributes) {
        this.$store.commit('SET_CLIENT_LEDGER_CONDITIONS', attributes)
      }
    },

    questionaireDescription() {
      let description = ''
      Object.entries(this.adminDefinitionsRiskTypes).forEach(([, obj]) => {
        if (this.riskToleranceCalc == obj.rank) {
          if (obj.description == 'Default') {
            description = obj.sysDescription
          } else {
            description = obj.indDescription
          }
        }
      })
      return description
    },

    questionaireRiskType() {
      let riskType = ''
      Object.entries(this.adminDefinitionsRiskTypes).forEach(([, obj]) => {
        if (this.riskToleranceCalc == obj.rank) {
          riskType = obj.name
        }
      })
      return riskType
    },

    timeHorizonScore() {
      return (
        parseInt(this.conditions.riskToleranceQuestion1) +
        parseInt(this.conditions.riskToleranceQuestion2)
      )
    },

    riskToleranceCalc() {
      return this.riskToleranceMatrix[this.timeHorizonScore - 1][
        parseInt(this.conditions.riskToleranceQuestion3) +
          parseInt(this.conditions.riskToleranceQuestion4) +
          parseInt(this.conditions.riskToleranceQuestion5) +
          parseInt(this.conditions.riskToleranceQuestion6) +
          parseInt(this.conditions.riskToleranceQuestion7) -
          1
      ]
    },

    currentTitle() {
      switch (this.step) {
        case 8:
          return 'Your Risktype: ' + this.questionaireRiskType
        default:
          return 'Find out your Risk Type'
      }
    },

    ...mapState(['clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
