<template>
  <div>
    <v-row class="mx-7 mt-2">
      <v-col cols="12" sm="12" md="12" lg="6">
        <v-card
          v-if="conditions.riskType != ''"
          flat
          color="white"
          class="primary--text text-center"
        >
          <v-row>
            <v-col cols="9" class="text-left">
              <v-card-title class="headline primary--text">
                Model Portfolio: {{ conditions.modelPortfolio.modelPortfolio }}
              </v-card-title>
            </v-col>

            <v-col cols="3" class="text-right">
              <v-card-text class="subtitle-1 primary--text">
                <v-btn
                  @click="dialogModelPortfolio = !dialogModelPortfolio"
                  small
                  text
                  outlined
                >
                  <v-icon color="success" class="title">mdi-pencil</v-icon
                  >change</v-btn
                >
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-text class="subtitle-1 primary--text text-left mt-n6">
            <div id="chart">
              <apexchart
                type="pie"
                width="550"
                height="550"
                :options="selectedData.chartOptions"
                :series="selectedData.series"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="dialogModelPortfolio" max-width="400px">
      <v-card>
        <v-card-title class="ml-3">
          <span class="headline mt-4">Model Portfolio</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-n4">
                <v-select
                  v-model="conditions.modelPortfolio.modelPortfolio"
                  :items="selectedData.modelPortfolioNames"
                  label="please select"
                  class="primary--text"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="mt-n6">
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>
          <v-btn class="primary--text mb-4" @click="cancel" text>Cancel</v-btn>
          <v-btn color="success" @click="update" class="mr-4 mb-4" text
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'clientProfileModelPortfolio',

  props: { selectedData: { type: Object }, conditions: { type: Object } },

  data() {
    return {
      dialogModelPortfolio: false
    }
  },

  methods: {
    update() {
      this.conditions.modelPortfolio = this.selectedData.modelPortfolio
      this.$store.dispatch('updateConditions').then(() => {
        this.dialogModelPortfolio = false
      })
    },

    cancel() {
      this.$store.dispatch('fetchClientLedger').then(() => {
        this.dialogModelPortfolio = false
      })
    }
  },

  computed: {
    ...mapState(['clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
