<template>
  <div>
    <the-client-layout-header
      :headline="headline"
      @confirmChanges="onConfirmChanges"
    />

    <v-card flat height="auto" class="d-flex align-stretch">
      <v-carousel
        v-model="caro"
        light
        height="auto"
        :show-arrows="false"
        hide-delimiters
        hide-delimiter-background
        ><v-carousel-item
          key="1"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="assumptions"
          class="mb-12"
          ><client-profile-assumptions :conditions="conditions" />
        </v-carousel-item>

        <v-carousel-item
          key="2"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="account1"
          class="mb-12"
          ><client-profile-person-one />
        </v-carousel-item>

        <v-carousel-item
          key="3"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="account2"
          class="mb-12"
          ><client-profile-person-two />
        </v-carousel-item>

        <v-carousel-item
          key="4"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="risktype"
          class="mb-12"
          ><v-row class="mx-7 mt-2">
            <v-col cols="12" sm="12" md="12" lg="6">
              <v-card flat color="white" class="primary--text text-center">
                <v-row>
                  <v-col cols="6" class="text-left">
                    <v-card-title class="headline primary--text">
                      {{ conditions.riskType }}
                    </v-card-title>
                  </v-col>

                  <v-col cols="6" class="text-right">
                    <v-card-text class="subtitle-1 primary--text">
                      <v-btn
                        @click="dialogSelect = !dialogSelect"
                        text
                        outlined
                        small
                        class="mb-2"
                      >
                        <v-icon color="success" class="title">mdi-pencil</v-icon
                        >change</v-btn
                      >
                      <v-btn
                        @click="dialogQuestionaire = !dialogQuestionaire"
                        text
                        outlined
                        small
                        class="ml-4 mb-2"
                      >
                        <v-icon color="success" class="title"
                          >mdi-file-question</v-icon
                        >Questionaire</v-btn
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-text class="subtitle-1 primary--text text-left mt-n6">{{
                  selectDescription
                }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <dialog-risk-type-select
            :selected-data="selectedData"
            :visible="dialogSelect"
            @close="onCloseDialogSelect"
          />

          <dialog-risk-type-questionaire
            :selected-data="selectedData"
            :visible="dialogQuestionaire"
            @close="onCloseDialogQuestionaire"
          />
        </v-carousel-item>

        <v-carousel-item
          key="5"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="modelportfolio"
          class="mb-12"
          ><client-profile-model-portfolio
            :selected-data="selectedData"
            :conditions="conditions"
          />
        </v-carousel-item>
      </v-carousel>

      <dialog-save-discard-changes ref="dialogChanges" />
    </v-card>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import TheClientLayoutHeader from '@/components/TheClientLayoutHeader.vue'
import DialogSaveDiscardChanges from '@/components/dialogs/DialogSaveDiscardChanges.vue'
import ClientProfileAssumptions from '@/components/ClientProfileAssumptions.vue'
import ClientProfilePersonOne from '@/components/ClientProfilePersonOne.vue'
import ClientProfilePersonTwo from '@/components/ClientProfilePersonTwo.vue'
import DialogRiskTypeSelect from '@/components/dialogs/DialogRiskTypeSelect.vue'
import DialogRiskTypeQuestionaire from '@/components/dialogs/DialogRiskTypeQuestionaire.vue'
import ClientProfileModelPortfolio from '@/components/ClientProfileModelPortfolio.vue'

export default {
  name: 'clientProfile',

  beforeRouteEnter(routeTo, routeFrom, next) {
    // making sure, when navigating to this page, the slider allways moves to "PROFILE" ( tab = 1 )
    store.commit('SET_CLIENT_LAYOUT_TAB', 1)
    store.commit('SET_CLIENT_PROFILE_CARO', routeTo.params.tabs)
    next()
  },

  // making sure to save, when navigating away to different secondary navigation
  beforeRouteUpdate(routeTo, routeFrom, next) {
    if (this.system.dataChanged === true) {
      console.log('data has changed, ask to confirm or decline')
      this.saveConfirmDialog(routeFrom.params.tabs).then(() => {
        next()
      })
    } else next()
  },

  // making sure to save, when navigating away to different primary navigation
  beforeRouteLeave(routeTo, routeFrom, next) {
    if (this.system.dataChanged === true) {
      console.log('data has changed, ask to confirm or decline')
      this.saveConfirmDialog(routeFrom.params.tabs).then(() => {
        this.$store.commit('SET_DATA_CHANGED', false)
        next()
      })
    } else next()
  },

  components: {
    TheClientLayoutHeader,
    DialogSaveDiscardChanges,
    ClientProfileAssumptions,
    ClientProfilePersonOne,
    ClientProfilePersonTwo,
    DialogRiskTypeSelect,
    DialogRiskTypeQuestionaire,
    ClientProfileModelPortfolio
  },

  data() {
    return {
      dialogSelect: false,
      dialogQuestionaire: false,
      profile: {
        assumptions: { id: 1, name: 'Assumptions' },
        account1: { id: 2, name: this.clientAccHolder1 },
        account2: { id: 3, name: this.clientAccHolder2 },
        risktype: { id: 4, name: 'Risk Type' },
        modelportfolio: { id: 5, name: 'Model Portfolio' }
      },
      assumptions: {}
    }
  },

  created() {
    // preset assumptions items and parameters when navigate to page for the first time
    this.presetAssumptions()
  },

  watch: {
    // watch change of data and if it happens
    conditionsDataChanged: 'dataChange'
  },

  methods: {
    // handler function for DialogSaveDiscardChanges component
    async saveConfirmDialog(pageFrom) {
      const confirmation = await this.$refs.dialogChanges.show(
        this.profile[pageFrom]
      )
      if (confirmation === 'save') {
        console.log('Confirmation - save changes')
        this.onConfirmChanges()
      }
      if (confirmation === 'discard') {
        console.log('Cancel - discard changes')
        this.$store.dispatch('fetchClientLedger').then(() => {
          this.presetAssumptions()
        })
      }
    },

    dataChange() {
      // this.dataChanged = this.conditionsDataChanged
      this.$store.commit('SET_DATA_CHANGED', this.conditionsDataChanged)
    },

    presetAssumptions() {
      this.assumptions = {}
      this.assumptions = Object.assign({}, this.clientLedger.ledger.conditions)
      console.log('step2 updated assumptions in component = ', this.assumptions)
      this.$store.commit('SET_DATA_CHANGED', false)
      // this.dataChanged = false
    },

    onConfirmChanges() {
      this.$store.dispatch('updateConditions').then(() => {
        console.log('step1 updated conditions in state = ', this.conditions)
        this.presetAssumptions()
      })
    },

    onCloseDialogSelect() {
      this.dialogSelect = false
    },

    onCloseDialogQuestionaire() {
      this.dialogQuestionaire = false
    },

    checkDataChanged(object1, object2) {
      const keys1 = Object.keys(object1)
      const keys2 = Object.keys(object2)
      if (keys1.length !== keys2.length) {
        return true
      }
      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return true
        }
      }
      return false
    }
  },

  computed: {
    headline() {
      return 'Profile / ' + this.page.name
    },

    caro: {
      get() {
        return this.system.caroClientProfile
      },
      set(profile) {
        this.$store.commit('SET_CLIENT_PROFILE_CARO', profile)
        this.$router.push({ name: 'clientProfile', params: { tabs: profile } })
      }
    },

    page() {
      let page = this.accounts[this.caro]
      return page
    },

    accounts() {
      let data = {
        assumptions: { id: 1, name: 'Assumptions' },
        account1: { id: 2, name: this.clientAccHolder1 },
        account2: { id: 3, name: this.clientAccHolder2 },
        risktype: { id: 4, name: 'Risk Type' },
        modelportfolio: { id: 5, name: 'Model Portfolio' }
      }
      return data
    },

    conditions: {
      get() {
        return this.clientLedger.ledger.conditions
      },
      set(attributes) {
        this.$store.commit('SET_CLIENT_LEDGER_CONDITIONS', attributes)
      }
    },

    dataChanged: {
      get() {
        return this.system.dataChanged
      },
      set(dataChanged) {
        this.$store.commit('SET_DATA_CHANGED', dataChanged)
      }
    },

    selectDescription() {
      let selectDescription = ''
      Object.entries(this.adminDefinitionsRiskTypes).forEach(([, obj]) => {
        if (this.conditions.riskType == obj.name) {
          if (obj.description == 'Default') {
            selectDescription = obj.sysDescription
          } else {
            selectDescription = obj.indDescription
          }
        }
      })
      return selectDescription
    },

    selectedData() {
      let data = {
        chartOptions: {},
        series: [],
        modelPortfolioNames: [],
        modelPortfolio: {}
      }
      Object.entries(this.advisorDefinitionsModelPortfolios).forEach(
        ([, val]) => {
          if (this.clientLedger.ledger.conditions.riskType == val.riskType) {
            data.modelPortfolioNames.push(val.modelPortfolio)
          }
          if (
            this.clientLedger.ledger.conditions.riskType == val.riskType &&
            this.clientLedger.ledger.conditions.modelPortfolio.modelPortfolio ==
              val.modelPortfolio
          ) {
            const series = []
            const labels = []
            data.modelPortfolio = val
            Object.entries(val.data).forEach(([, obj]) => {
              series.push(obj.y)
              labels.push(obj.x)
            })
            data.series = series
            data.chartOptions = {
              colors: [
                '#BBBBBB',
                '#828282',
                '#55677D',
                '#FFC46D',
                '#007EB9',
                '#FFAC31',
                '#31465F',
                '#FF9900',
                '#B71C1C'
              ],
              labels: labels,
              dataLabels: {
                enabled: false
              },
              yaxis: {
                type: 'numeric',
                labels: {
                  formatter: function(value) {
                    return value + ' %'
                  }
                }
              },
              legend: {
                show: true,
                showForNullSeries: true,
                position: 'right',
                formatter: function(seriesName, opts) {
                  return opts.w.globals.series[opts.seriesIndex] <= 9
                    ? [
                        '  ',
                        opts.w.globals.series[opts.seriesIndex],
                        ' % ',
                        seriesName
                      ]
                    : [
                        opts.w.globals.series[opts.seriesIndex],
                        ' %  ',
                        seriesName
                      ]
                },
                offsetY: 0,
                offsetX: -20
              },
              tooltip: {
                enabled: true
              }
            }
          }
        }
      )

      return data
    },

    // check, if data has been changed to enable showing of save changes button and save changes dialog
    conditionsDataChanged() {
      return this.checkDataChanged(
        this.assumptions,
        this.clientLedger.ledger.conditions
      )
    },

    ...mapState(['system', 'client', 'clientLedger', 'clientProjection']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
