<template>
  <div>
    <v-dialog persistent v-model="visible" max-width="400px">
      <v-card>
        <v-card-title class="ml-3">
          <span class="headline mt-4">Risk Type</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-n4">
                <v-select
                  v-model="conditions.riskType"
                  :items="adminDefinitionsRiskTypeNames"
                  label="please select"
                  class="primary--text"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="mt-n6">
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>
          <v-btn class="primary--text mb-4" @click="cancel" text>Cancel</v-btn>
          <v-btn color="success" @click="update" class="mr-4 mb-4" text
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'dialogRiskTypeSelect',

  props: {
    visible: { type: Boolean, default: false },
    selectedData: { type: Object }
  },

  data() {
    return {}
  },

  methods: {
    update() {
      this.conditions.modelPortfolio = this.selectedData.modelPortfolio
      this.$store.dispatch('updateConditions').then(() => {
        this.$emit('close')
      })
    },

    cancel() {
      this.$store.dispatch('fetchClientLedger').then(() => {
        this.$emit('close')
      })
    }
  },

  computed: {
    conditions: {
      get() {
        return this.clientLedger.ledger.conditions
      },
      set(attributes) {
        this.$store.commit('SET_CLIENT_LEDGER_CONDITIONS', attributes)
      }
    },

    ...mapState(['clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
