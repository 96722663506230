<template>
  <div>
    <v-card flat class="primary--text">
      <v-row class="ma-3">
        <v-col cols="12" sm="4" md="3" lg="3"
          ><template>
            <v-card outlined class="mx-auto">
              <v-card-text>
                <v-row class="mt-n5" justify="space-between"
                  ><v-col class="text-left">
                    <span class="overline primary--text font-weight-normal"
                      >APP - CURRENCY
                    </span>
                  </v-col></v-row
                >
                <v-row class="mt-n8" justify="space-between">
                  <v-col
                    cols="8"
                    sm="12"
                    md="12"
                    lg="7"
                    class="text-left mt-n3 mb-n4"
                    ><v-select
                      v-model="conditions.accountCurrency"
                      :items="unitFiat"
                      class="primary--text"
                    ></v-select
                  ></v-col>
                  <v-col
                    cols="4"
                    sm="12"
                    md="12"
                    lg="5"
                    class="text-center mt-n2"
                    ><span
                      class="display-1 primary--text font-weight-light mr-1"
                      v-text="conditions.accountCurrency"
                    ></span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-col>

        <v-col cols="12" sm="4" md="3" lg="3"
          ><template>
            <v-card outlined class="mx-auto">
              <v-card-text>
                <v-row class="mt-n5" justify="space-between"
                  ><v-col class="text-left">
                    <span class="overline primary--text font-weight-normal"
                      >INFLATION RATE
                    </span>
                  </v-col></v-row
                >
                <v-row class="mt-n8" justify="space-between">
                  <v-col cols="8" sm="12" md="12" lg="7" class="text-left mt-2"
                    ><v-slider
                      v-model="conditions.inflationRate"
                      color="primary"
                      track-color="grey"
                      min="0"
                      max="6"
                      step="0.1"
                      class="mb-n5"
                    >
                      <template v-slot:prepend>
                        <v-icon color="success" @click="decrementInflRate">
                          mdi-minus
                        </v-icon>
                      </template>

                      <template v-slot:append>
                        <v-icon color="success" @click="incrementInflRate">
                          mdi-plus
                        </v-icon>
                      </template>
                    </v-slider></v-col
                  >
                  <v-col
                    cols="4"
                    sm="12"
                    md="12"
                    lg="5"
                    class="text-center mt-n2"
                  >
                    <span
                      class="display-1 primary--text font-weight-light mr-1"
                      v-text="conditions.inflationRate"
                    ></span>
                    <span class="subheading font-weight-light mr-1">%</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-col>

        <v-col cols="12" sm="4" md="3" lg="3"> </v-col>

        <v-col cols="12" sm="4" md="3" lg="3"> </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'clientProfileAssumptions',

  props: { conditions: { type: Object } },

  data() {
    return {
      // dialog: false,
      unitFiat: StaticDataService.getUnitFiat()
    }
  },

  methods: {
    incrementInflRate() {
      this.conditions.inflationRate += 0.1
    },

    decrementInflRate() {
      this.conditions.inflationRate -= 0.1
    }
  },

  computed: {
    unitFiatText() {
      let text = ''
      Object.entries(StaticDataService.getUnitFiat()).forEach(([, obj]) => {
        if (obj.value == this.currencyApp) {
          text = obj.text
        }
      })
      return text
    },

    ...mapState(['clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
